.field-wrapper {
  .open-editor-icon {
    right: 15px !important;
    top: 15px !important;
    .icon-full-screen {
      font-size: 20px;
      color: rgb(128 128 128 / 70%);
    }
  }
  .command-input {
    position: relative;
  }
  .cm-theme-light {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.command-field {
  .k-card-header {
    padding: 10px;
  }
  .card-title-secondary {
    margin: 0;
    font-size: 24px;
    color: #354052;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
  }
  .k-card-body {
    padding-block: 8px;
    padding-inline: 8px;
  }
  .lcs-action {
    margin-top: 10px;
  }
}
