@import "../../../../../assets/scss/abstracts/variables";
.app-key-body {
  .k-input-inner {
    font-size: 16px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid $light-grey;
  }
  .k-textbox {
    border: none !important;
  }
}
