.configuration-container {
  display: flex;
  justify-content: flex-start; /* Align cards to the left */
  .configuration-card {
    width: 100%;
    .profile-listview {
      width: "100%";
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 7px;
      .setup-col {
        width: 40%;
        display: flex;
      }
      .action-col {
        width: 60%;
      }
      .setup-name {
        font-size: 14px;
        margin-bottom: 0px;
        line-height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
      .k-chip-content {
        font-size: 9px;
      }
    }
    .k-listview {
      .listview-header {
        padding-top: 15px;
        color: rgb(160, 160, 160);
        font-size: 1.5em;
        padding-left: 7px;
      }
    }
  }
}
.slider-content-card {
  .k-animation-container.k-animation-container-relative {
    width: 100% !important;
  }
}

.configuration-card .k-input,
.configuration-card .k-button,
.configuration-card .k-dropdownlist {
  margin: 10px; /* Add margin between input fields and buttons */
}

.k-dialog .k-input,
.k-dialog .k-button,
.k-dialog .k-dropdownlist {
  margin-bottom: 20px !important; /* Add margin between input fields and buttons */
}

.k-loading-image {
  color: #4b127d;
  z-index: 10000004 !important;
}

.k-dialog-actions {
  border: 0 !important;
}

.qr-button {
  padding: 5px 10px !important;
}

