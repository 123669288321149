@import '../../../../assets/scss/abstracts/variables';
@import '../../../../assets/scss/abstracts/breakpoints';

.login-logo {
  width: 500px;
}

.login-logo-mobile {
  width: 100px;
  margin-bottom: 20px;
}

.login-title {
  font-size: 26px;
  color: $dark;
  font-weight: 400;
  text-transform: none;
  letter-spacing: -0.36px;
}

.login-button {
  padding: 5px 80px !important;
  background-color: $dark-grey-color !important;
  border: 1px solid $dark-grey-color !important;
  text-transform: none;
  font-size: 16px;
  margin-top: 10px;
}

.login-bg {
  width: 100%;
  height: 100vh;
  //background-image: url('../../../assets/images/bg12.jpeg');
  background-color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 10px 5px 5px lightgray;
  opacity: 0.09;
}

.bg-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.login-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  .login-rside {
    background-color: $black-color;

    img {
      width: 300px;
    }

    h2 {
      color: #fff;
      font-weight: 700;
      margin-top: 50px;
    }
  }

  .login-fside {
    background-color: $white-color;

    .microsoft-login-btn,
    .google-login-btn {
      width: 100%;
    }

    .login-button-wrapper {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .login-rside,
  .login-fside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.login-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 0;

  .k-form {
    padding: 0 16px;
  }

  .signup-link {
    padding: 0 16px;
  }

  &.keyboard-close {
    justify-content: start;
    margin-top: 20px;
  }

  width: 100%;

  .login-logo {
    margin-bottom: 15px;
    width: 250px;
  }

  .login-button-wrapper {
    padding: 14px 16px;

    .google-login-btn,
    .microsoft-login-btn {
      width: 100%;
    }
  }
}

.login-grid-mobile {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  min-height: 100vh;
  background-color: $white-color;
}

.login-grid.error {
  .login-title {
    margin-bottom: 0;
    line-height: 1.4;
  }

  .alert-info {
    margin: 0;
  }
}

.k-font-bold {
  font-weight: 700 !important;
}

.k-login-input.k-input-solid {
  border: 1px solid $light-grey !important;
  border-radius: 6px !important;
  background-color: $white-smoke;
  font-size: 16px;
}

.k-login-input.k-invalid.k-input-solid {
  border-color: $error-color !important;
}

.form-container {
  width: 50%;
}

.form-container-mobile {
  width: 70%;
}

.k-w-100 {
  width: 100%;
}
.\!k-w-100 {
  width: 100% !important;
}

.signup-link {
  font-size: 14px;
  text-align: right;
  margin-top: 12px;
}

.text-danger {
  color: red;
  margin-left: 4px;
}

.google-login-btn {
  margin-top: 15px;
}

@include media('<=phone') {
  .form-container-mobile {
    width: 100%;
  }
}
.form-container .k-form-field {
  margin-top: 8px !important;
}
