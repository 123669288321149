@import '../../../assets/scss/abstracts/variables';

.product-row {
  display: flex;
  flex-wrap: wrap;

  .product-col {
    width: 100%;
    margin: 0;
  }
  .subscription-feature-title .expiry-date {
    display: block;
    color: $light-grey-color;
    line-height: normal;
  }
  .edit-feature-icon {
    color: #3f51b5;
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
  }
  .subscription-feature-title label {
    word-wrap: break-word;
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: $grid-text;
    line-height: 18px;
  }

  .product-subscription-card {
    width: 100%;
    // border: 1px solid #e0e0e0; /* Adjust border color for a more professional look */
    border-radius: 0;
    overflow: hidden; /* Ensure content doesn't overflow */
  }

  .product-subscription-header {
    padding: 15px 5px;
    margin-top: 15px;
    border: 0;
    //border-bottom: 1px solid #e0e0e0; /* Adjust border color for header */
  }

  .product-subscription-title,
  .k-expander-title {
    font-size: 16px; /* Adjusted font size for product name */
    font-weight: 600;
    color: $dark; /* Adjusted text color for product name */
    margin-bottom: 10px; /* Added margin to separate product name from features */
  }

  .product-subscription-body {
    padding: 12px;
    margin-top: 0;
  }

  .subscription-features-list {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
  }

  .subscription-feature-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .subscribe-button {
    padding: 6px 20px;
    background-color: $primary;
    font-size: 15px;
    color: $light;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 0;

    &:disabled {
      background-color: #ececec;
      background-image: unset;
      color: $dark-grey-color;
      cursor: default;
      &:hover {
        background-color: #ececec;
      }
    }

    &.subscribed {
      background-color: $success-color;
      color: white;
      &:hover {
        background-color: $success-color;
      }
    }
  }
}

.subscribe-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.Products-page {
  &.page {
    border: 0;
    box-shadow: unset;

    .page-title {
      background-color: #fff;
    }
  }

  border: 0;

  .container {
    background: unset;

    .k-card.product-subscription-card {
      background: unset;
    }
  }

  .k-expander {
    background-color: transparent;
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 20px;
  }

  .k-expander-content {
    padding: 0px 10px;
  }

  .k-expander-header {
    padding: 12px 12px;

    .k-expander-title {
      margin-bottom: 0;
    }
  }
}

.checkbox-error + label {
  color: $red-color;
}

.text-error {
  color: $red-color;
}
.product-page {
  .product-listview {
    width: '100%';
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.k-expander {
  &.k-focus {
    box-shadow: unset !important;
  }
  &.k-expanded {
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
}
