.execution-main {
  .execution-details {
    .grid-wrapper {
      padding: 10px 10px;
    }
    .k-tabstrip-content {
      padding: 0px;
    }
    .gridfilter-topbar {
      display: none;
    }
    .k-animation-container.k-animation-container-relative {
      width: 100%;
    }
  }
}
