@import "./../abstracts/variables";
@import "./../abstracts/breakpoints";
.command-syntax-content {
  border-radius: 6px !important;
  border: 1px solid #fff !important;
  background: #fff;
}
.lcs-action {
  margin-top: 10px;
}
span.required-field {
  color: $error-color;
  margin-left: 2px;
}
.field-invalid {
  color: $error-color;
  input,
  .k-textarea,
  .k-dropdownlist,
  .command-input {
    border: 1px solid $error-color !important;
  }
  .k-form-error.k-text-start {
    line-height: 0;
    position: relative;
    top: 5px;
  }
}

.single-field-row {
  .field-wrapper {
    .k-filtercell {
      .k-input-inner {
        border: 0;
      }
    }
  }
}

.content-slider-form {
  .single-field-row {
    width: 100%;
    .field-wrapper {
      width: 100%;
      padding-block: 5px;
      .k-textbox {
        padding: 0;
      }
      .k-input-inner {
        border: 0;
        padding: 0px;
      }
    }
    .k-form-field {
      width: 100%;
    }
  }
}

.single-field-row {
  .field-wrapper {
    width: 50%;
    .k-floating-label-container {
      width: 100%;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      color: #757575;
    }
    .k-input-solid {
      background-color: $white-smoke;
    }
    .k-input-inner {
      font-size: 16px;
    }
    .k-dropdownlist {
      .k-input-inner {
        border-radius: none;
        border: unset;
      }
    }
    .k-textbox {
      border: none !important;
    }
    .k-dropdownlist,
    .k-input-solid {
      box-shadow: none;
    }
    .k-checkbox-wrap + .k-checkbox-label {
      margin-inline-start: 20px !important;
    }
    .k-form-error {
      padding-bottom: 5px;
    }
    .k-switch-on:focus .k-switch-track,
    .k-switch-on.k-focus .k-switch-track {
      outline: none;
    }
    .k-switch-on .k-switch-track {
      border: none;
    }
  }
  .field-wrapper-cmd {
    position: relative;
    .display-text {
      max-height: 40px;
      overflow: auto;
      display: block;
    }
  }
  .field-wrapper-inline {
    display: flex;
    width: 50%;
    .field-inline-main {
      width: 50%;
    }
  }
  .field-wrapper-100 {
    width: 100%;
  }
  .field-wrapper-75 {
    width: 75%;
  }
  span.k-floating-label-container.k-label {
    margin: 2px 0;
  }
}

.button-form-wrapper {
  display: flex !important;
  justify-content: right;
}

@include media("<=tablet") {
  .k-split-button .k-button-text {
    display: block !important;
  }
  .main-page-section .card-detail-content .card-title-header {
    border: 0;
  }
  .single-field-row {
    display: block;
    .field-wrapper {
      width: 100% !important;
    }
    .field-wrapper {
      .k-form-field-wrap {
        width: 100% !important;
      }
      .k-form-field.switch-wrapper {
        margin-block: 10px;
      }
    }
    .field-wrapper-inline {
      display: block;
      width: 100% !important;
      .field-inline-main {
        width: 100% !important;
      }
    }
    .field-wrapper-75 {
      width: 100%;
    }
  }
}
.slider-content-card {
  .k-input {
    padding: 0.5rem 1rem !important;
    line-height: unset !important;
    min-height: unset !important;
    height: unset !important;
  }
  .k-input.k-datepicker {
    padding: 0.2rem 1rem !important;
  }
  .k-input.k-dateinput {
    padding: 0px !important;
  }
  .k-textarea {
    height: unset !important;
  }
  .field-wrapper-placeholder {
    .k-datepicker {
      .k-dateinput {
        input {
          color: rgba(128, 128, 128, 0.7);
        }
      }
    }
  }
  .k-datepicker {
    .k-svg-icon {
      color: rgba(128, 128, 128, 0.7);
    }
  }
  .k-dropdownlist {
    padding-left: 10px;
  }
}
.app-key-wrapper {
  textarea {
    padding-right: 40px !important;
  }
}
span.k-floating-label-container.k-label {
  padding-top: 0px;
  padding-bottom: 0px;
}
.slider-content-card .slider-card .k-form-fieldset {
  border: none;
  margin-inline: 0px;
  padding-inline: 0px;
}
.filtercell,
.k-filtercell {
  line-height: 0px !important;
}
.test-dropdown-wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
  .dropdown-wrapper {
    flex: 1;
    min-width: 0; /* This prevents flex child from overflowing */
  }
}
