.page-tabs {
  .grid-wrapper {
    padding-left: 0;
  }
  .tabs-card {
    .k-animation-container.k-animation-container-relative {
      display: block;
    }
  }
  .k-card-body {
    padding: 0;
  }
}
.k-tabstrip-items-wrapper .k-item {
  box-shadow: unset !important;
}
.detail-grid-section .k-tabstrip-items-wrapper .k-item.k-active::after {
  margin-inline: 0px !important;
}
