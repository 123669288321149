.data-field-section {
  .slider-content-card {
    width: 80% !important;
  }
  .content-slider-actions {
    width: -webkit-fill-available;
  }
  .slider-card {
    padding-bottom: 10px;
  }
  .data-field-slider {
    .k-input {
      height: 100% !important;
    }
    .k-card-body {
      height: 80vh;
      .single-field-row {
        height: 100%;
        .data-field-wrapper {
          padding-top: 10px !important;
          padding-bottom: 10px !important;
        }
        .k-form-field,
        .k-form-field-wrap {
          height: 100%;
        }
      }
    }
  }
}

.data-field-wrapper {
  position: relative;
  .open-editor-icon {
    top: 57px !important;
    right: 28px !important;
  }
  textarea {
    padding-right: 30px !important;
  }
}
