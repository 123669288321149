@import '../../../../assets/scss/abstracts/variables';
.label-msg {
  font-size: 11px;
  padding: 3px 5px 2px 5px;
  border-radius: 3px;
  margin-left: 5px;
  color: $white-color;
  font-weight: 400;
  text-transform: capitalize;
  &.error {
    background: $danger-color;
  }
  &.success {
    background: $success-color;
  }
  &.primary {
    background: $primary;
  }
}
