.argument-grid {
  .k-floating-label-container {
    padding: unset !important;
  }
  .k-table-td .k-input {
    border-radius: var(--kendo-border-radius-md, 0.25rem);
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    border-style: solid;
    line-height: 1.25 !important;
    box-shadow: none;
    align-items: stretch;
    background-color: #f7f7f7;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-width: 1px 0;
    border-top-color: transparent !important;
    padding: 0.5rem 1rem !important;
  }

  .k-table-td .k-input:focus {
    border-color: var(--kendo-color-primary, #3f51b5);
  }
}
