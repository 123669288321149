@import '../../../../assets/scss/abstracts/variables';

.k-form-field {
  width: 100%;
}
.lookup-mode {
  .grid-wrapper.tree-grid-wrapper {
    padding: 0 !important;
  }
  .add-app-flow-step-button {
    padding-right: 0 !important;
  }
}
.grid-wrapper.tree-grid-wrapper {
  padding: 0 1.01rem !important;
  .k-grid {
    border-radius: 5px 5px 0 0;
  }
  .k-grid-norecords td {
    padding: 0 !important;
  }
  .k-table-th.k-header {
    background: white;
  }
  .k-grid-norecords {
    align-items: center;
    text-align: center;
  }
  .k-treelist-scrollable .k-grid-header .k-table-th {
    background: white;
  }
  .k-cell-inner {
    background: rgba(0, 0, 0, 0.07);
  }
}
#root[device-type='Phone'] {
  .lookup-mode .slider-content-card .slider-content-card:has(.small-slider) {
    width: 100% !important;
  }
  .lookup-mode .slider-content-card .k-card-actions:has(.small-slider-action) {
    width: 100% !important;
  }
}
.lookup-mode {
  .slider-content-card {
    .slider-content-card:has(.small-slider) {
      width: 600px !important;
      .slider-card .k-card-body {
        padding: 0 16px;
      }
    }
    .k-card-actions:has(.small-slider-action) {
      width: 600px !important;
    }
    .k-card-actions:has(.large-slider-action) {
      width: 80% !important;
    }
    .slider-content-card:has(.large-slider) {
      width: 80% !important;
      .slider-card .k-card-body {
        padding: 0 16px;
      }
    }
  }
}
