.slider-content-card {
  .k-card-actions:has(.large-slider-action) {
    width: 80% !important;
  }

  .slider-content-card:has(.large-slider) {
    width: 80% !important;
  }
}

.k-floating-label-container label {
  color: #757575;
}
