@import '../../../../../assets/scss/abstracts/variables';
.lookup-footer-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.lookup-tree {
  .k-table-td.k-text-nowrap {
    font-size: 14px;
    line-height: 1.4285714286;
    color: $component-text;
  }
}
.test-dropdown-wrapper {
  .k-form-field {
    width: 100%;
  }
}
