@import "../../../assets/scss/abstracts/variables";
@import "../../../assets/scss/abstracts/breakpoints";
.organization-page {
  .product-row {
    &.page {
      border: 0;
      box-shadow: unset;

      .page-title {
        background-color: #fff;
      }
    }
    border: 0;
    .container {
      background: unset;
      .k-card.product-subscription-card {
        background: unset;
      }
    }
    .k-expander {
      background-color: transparent;
      width: 100%;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-top: 20px;
      &.k-focus {
        box-shadow: unset;
      }
      &.k-expanded {
        box-shadow:
          rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
    }
    .k-expander-content {
      padding: 12px;
    }
    .k-expander-header {
      padding: 12px 6px;
      .k-expander-title {
        margin-bottom: 0;
      }
    }
  }
}
.k-expander {
  width: 100%;
}
.application-key-card {
  .k-card-header {
    padding: 5px 10px;
  }
  .card-title-secondary {
    margin: 0;
    font-size: 20px;
  }
  .k-card-body {
    padding-block: 10px !important;
  }
}
