@import "../../../../assets/scss/abstracts/breakpoints";
.execution-analytics {
  .date-form {
    display: flex;
  }
  .test-cases-result-row {
    display: flex;
    .test-cases-result {
      width: 50%;
      border: 1px solid #e2e6ec;
    }
  }
  .test-cases-plaform {
    width: 100%;
    border: 1px solid #e2e6ec;
  }
}
@include media("<=tablet") {
  .execution-analytics {
    .test-cases-result-row {
      display: block;
      .test-cases-result {
        width: 100%;
      }
    }
    .date-form {
      display: block;
      div {
        padding-block: 10px;
      }
    }
  }
}
