.test-main {
  .k-animation-container.k-animation-container-relative.slider {
    width: 80%;
  }
  .slider-content-card {
    width: 100%;
  }
  .test-doc-slider {
    h3,
    h4,
    h5,
    h6 {
      font-size: 16px;
      font-weight: 500;
      strong {
        font-weight: 600;
      }
    }
    h1 {
      padding-bottom: 0.3em;
      padding-top: 0.3em;
      font-size: 24px;
      font-weight: 500;
      border-bottom: 1px solid #d0d7deb3;
      line-height: unset;
      font-family: Roboto, 'Helvetica Neue', sans-serif;
      strong {
        font-weight: 600;
      }
    }
    h2 {
      padding-bottom: 0.3em;
      padding-top: 0.3em;
      font-size: 20px;
      font-weight: 600;
      border-bottom: 1px solid #d0d7deb3;
      line-height: unset;
      font-family: Roboto, 'Helvetica Neue', sans-serif;
      strong {
        font-weight: 600;
      }
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 16px;
    }
    table {
      border-collapse: collapse;
      margin-bottom: 16px;
      tr:nth-child(2n) {
        background-color: #f6f8fa;
      }
      th,
      td {
        padding: 6px 13px;
        border: 1px solid #d0d7de;
      }
    }
    hr {
      height: 0.25em;
      padding: 0;
      margin: 24px 0;
      background-color: #d0d7de;
      border: 0;
    }
    li p {
      margin: 5px 0px;
    }
  }
}
