@import "../../../../assets/scss/abstracts/breakpoints";
.runset-detail {
  .test-case-dropdown-label {
    text-wrap: nowrap !important;
  }
  .test-case-item:hover {
    color: #fff !important;
    background-color: #3f51b59e !important;
  }

  .add-test-case-row {
    display: flex;
  }
  .test-case-item {
    width: 100%;
  }
  .testcase-dropdown {
    width: 100%;
  }
  @include media("<=tablet") {
    .add-test-case-row {
      display: block;
      .test-case-dropdown-label {
        padding-block: 10px;
      }
      .testcase-dropdown {
        margin-bottom: 10px;
      }
    }
  }
}
