.grid-wrapper {
  .action-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .k-button-group {
      box-shadow: unset;
    }
    .action-icon-wrapper {
      border: none;
      font-size: 14px;
      padding: 0px 8px;
      border-radius: 4px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}
.k-button-md {
  padding-block: 5px;
  padding-inline: 10px;
}
.k-split-button .k-split-button-arrow.k-button-md {
  padding-block: 5px;
  padding-inline: 4px;
}
.detail-trash-icon::before {
  color: rgb(128 128 128 / 70%) !important;
  font-size: 22px !important;
  cursor: pointer;
}
