@import '../../../assets/scss/abstracts/variables';
@import '../../../assets/scss/abstracts/breakpoints';

#root[device-type='Desktop'] {
  .page-detail {
    .card-actions-content {
      .card-actions {
        display: flex !important;
      }
    }
  }
  .layout-no-permission {
    .alert.alert-suggestion {
      margin-top: 16%;
    }
  }
}
#root[device-type='Tablet'] {
  // add tablet based specific media queries based on resize worker
  .k-notification-group {
    &,
    .k-notification-containe {
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
    }
    .k-animation-container {
      background: transparent;
      width: 100%;
      .k-notification-container {
        float: unset;
      }
    }
  }
  .layout-no-permission {
    width: 100% !important;
    .main-no-permission-label {
      text-align: center;
    }
    .alert-suggestion {
      background-color: transparent;
      border-color: transparent;
    }
  }
  .slider-content-card {
    .slider-card {
      height: calc(100vh - 63px) !important;
      overflow: auto;
      padding-bottom: 28px;
      .k-card-body {
        margin-bottom: 6em;
      }
    }
    .content-slider-actions {
      position: fixed;
      bottom: 0;
    }
  }
  .page-detail {
    background: none;
    .card-actions-content {
      display: grid !important;
      position: fixed;
      bottom: 0;
      .card-actions {
        position: fixed !important;
        border-top: 2px solid $component-border;
        box-shadow:
          0 3px 1px -2px rgb(0 0 0 / 20%),
          0 2px 2px 0 rgb(0 0 0 / 14%),
          0 1px 5px 0 rgb(0 0 0 / 12%);
      }
    }
    .card-detail-content {
      margin-bottom: 160px;
      .k-loading-mask {
        &.page-detail-loader {
          position: fixed !important;
        }
      }
      @include media($tablet-only-portrait-mode...) {
        .orientation-Portrait {
          .k-step-list-horizontal {
            .k-step-indicator + .k-step-label {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
  @include media('>tablet') {
    .k-drawer-expanded {
      .home-page {
        .k-tilelayout {
          &.k-grid-flow-col {
            grid-template-columns: repeat(3, minmax(0px, 1fr)) !important;
          }
        }
      }
    }
  }
  //landscape orientation css styles
  &[device-orientation='Landscape'] {
    .page-detail {
      .card-actions-content {
        .card-actions {
          justify-content: flex-end;
          display: grid !important;
        }
      }
    }
  }
  // portrait orientation css styles
  &[device-orientation='Portrait'] {
    .slider,
    .slider-content-card {
      width: 100%;
      .content-slider-actions {
        width: 100%;
      }
    }
    @include media('<=phone') {
      .home-page {
        .k-tilelayout {
          &.k-grid-flow-col {
            grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
          }
        }
      }
    }
    .page-detail {
      .card-detail-content {
        height: calc(100vh - 30vh) !important;
        .description-card {
          .description {
            .k-form-field-wrap {
              .k-input {
                width: 100%;
              }
            }
          }
        }
        .orientation-Portrait {
          .stepper-body {
            height: calc(100vh - 184px - 19vh);
            overflow: scroll;
          }
        }
      }
    }
  }
}
#root[device-type='Phone'] {
  // add phone based specific media queries based on resize worker
  .k-notification-group {
    &,
    .k-notification-containe {
      width: 100%;
    }
    .k-animation-container {
      background: transparent;
      width: 100%;
      .k-notification-container {
        float: unset;
      }
    }
  }
  .slider,
  .slider-content-card {
    width: 100%;
    .slider-card {
      height: calc(100vh - 63px) !important;
      overflow: auto;
      padding-bottom: 28px;
    }
    .content-slider-actions {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
  .page-detail {
    background: none;
    .card-actions-content {
      display: grid !important;
      position: fixed;
      bottom: 0;
      .card-actions {
        position: fixed !important;
        border-top: 1px solid $component-border;
        box-shadow:
          0 3px 1px -2px rgb(0 0 0 / 20%),
          0 2px 2px 0 rgb(0 0 0 / 14%),
          0 1px 5px 0 rgb(0 0 0 / 12%);
      }
    }
    .card-detail-content {
      margin-bottom: 136px;
    }
  }
  .layout-no-permission {
    .alert-suggestion {
      background-color: transparent;
      border-color: transparent;
    }
    .main-no-permission-label {
      text-align: center;
    }
  }

  &[device-orientation='Portrait'] {
    .home-page {
      .k-tilelayout {
        &.k-grid-flow-col {
          grid-template-columns: repeat(1, minmax(0px, 1fr)) !important;
        }
      }
    }
    .page-detail {
      .card-detail-content {
        height: calc(100vh - 25vh) !important;
        .orientation-Portrait {
          .stepper-body {
            height: calc(100vh - 45vh);
            overflow: scroll;
          }
        }
        .description-card {
          .description {
            .k-form-field-wrap {
              .k-input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.eq-lookup .k-card-actions {
  width: 80%;
}
