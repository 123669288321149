.slider-content-card {
  .copy-button-container {
    position: absolute;
    right: 6px;
    top: 48px;
    z-index: 999;
  }
  .base64-field {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 15px;
    .k-input {
      line-height: unset !important;
      min-height: unset !important;
      height: 230px !important;
      padding-right: 38px;
    }
  }
  .flip-card-container {
    perspective: 1000px; /* Provides depth for the 3D effect */
  }

  .flip-card {
    position: relative;
    width: 100%;
    height: 270px;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    cursor: pointer;
  }

  .flip-card.flipped {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    overflow: hidden; /* Ensures no content spills out */
  }

  .flip-card-back {
    transform: rotateY(180deg);
  }
  .flip-card-front,
  .flip-card-back {
    box-sizing: border-box;
  }
}
