@import '../../../assets/scss/abstracts/variables';
@import '../../../assets/scss/abstracts/breakpoints';
.profile-page {
  .user-profile {
    width: 100%;
  }
  .profile-detail-image {
    width: 130px;
  }
  .card-row {
    display: flex;
  }
  .single-field-row .field-wrapper {
    width: 100%;
  }
  .card-column {
    &.user-av-wrapper {
      width: 25%;
      display: flex;
      justify-content: center;
    }
    &.fields-column {
      width: 75%;
    }
  }
  .field-row {
    div {
      width: 50%;
    }
  }
  strong.field-label {
    font-size: 16px;
    margin-right: 5px;
  }
  span.field-value {
    font-size: 16px;
  }
  .k-avatar-image {
    position: relative;
  }
  .user-avatar-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    svg {
      margin: 0;
    }
  }
  @include media('<=tablet') {
    .card-row {
      display: block;
    }
    .card-column {
      padding: 0 10px;
      &.user-av-wrapper {
        width: 100%;
      }
      &.fields-column {
        width: 100%;
      }
    }
    .field-row {
      display: block;
      div {
        width: 100%;
        text-align: center;
      }
    }
  }
}
.tile-layout-card {
  margin: 10px;
  .k-card-body {
    padding: 0;
    &.app-key-body {
      padding: 0 10px;
      .k-floating-label-container {
        width: 100%;
      }
    }
  }
  .k-form {
    padding: 0;
  }
  .k-card-actions {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 12px;
    .button-form-wrapper {
      margin-top: 0;
      align-items: end;
      width: 100%;
    }
  }
  .k-form-buttons {
    margin-bottom: 0;
  }
}
