@import '@progress/kendo-font-icons/dist/index.css';
@import './base/base';
@import './base/icon';
@import './abstracts/breakpoints';
@import './common/Slider';
@import './common/Input';
@import './common/Grid';
@import './common/ActionButtons';
@import './common/Tabs';

html,
body {
  margin: 0;
  background-color: $body-bg-color;
  overscroll-behavior-y: contain;
}
.k-card-title {
  color: $topbar-title-color;
}
.k-form {
  padding: 14px 16px;
}

.page-title,
.k-card-header {
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $component-border;
  padding: 14px 16px;
  color: $topbar-title-color;
  h4 {
    margin: 0;
    font-size: 24px;
    font-family: Arial;
    line-height: 1.25;
    font-weight: 500;
  }
  &.page-button-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    h4 {
      margin-bottom: 0;
    }
  }
  h5 {
    margin-bottom: 0;
  }
}

/* Styling for TopBar Header in mobile/tablet view*/
.header-bold {
  margin: 0;
  font-size: 24px;
  font-family: Arial;
  line-height: 1.25;
  font-weight: 500;
  color: $white-color;
}

.page {
  margin: 10px;
  padding: 0;
  border-color: $component-border;
  color: $component-text;
  background-color: $white-color;
  border-radius: 4px;
  outline: 0;
  box-shadow: unset;
  border-width: 1px;
  border-style: solid;
  box-shadow:
    0.7px 0.7px 0.5px rgba(0, 0, 0, 0.04),
    1.7px 1.7px 1.2px rgba(0, 0, 0, 0.03),
    3.1px 3.1px 2.1px rgba(0, 0, 0, 0.025),
    5.1px 5.1px 3.5px rgba(0, 0, 0, 0.022),
    8.5px 8.5px 5.8px rgba(0, 0, 0, 0.018),
    14.8px 14.8px 10.2px rgba(0, 0, 0, 0.015),
    32px 32px 22px rgba(0, 0, 0, 0.01);
  .card-detail-content {
    .action-buttons-container {
      a {
        text-decoration: none !important;
      }
    }
    .card-title-header {
      display: flex;
      align-items: center;
      padding: 0 10px;
      background: $white-color;
      border-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.87);
      background-color: $white-color;
      .page-title.page-button-title {
        border: 0;
      }
      span {
        &.sis-ico-arrow-left {
          display: inherit;
          color: #354052;
          font-size: 26px;
        }
      }
    }

    .k-card {
      &.Separate-Card {
        box-shadow: none;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        .k-card-title {
          font-size: 14px;
          font-weight: 700;
          color: $card-title-color;
        }
      }
    }
  }
}
@include media('<=phone') {
  .page {
    margin: 0;
    padding: 0;
  }
}
// consistent styling for button groups
.k-button-group {
  box-shadow: none;
}

.rounded-checkbox {
  &.k-checkbox,
  input[type='checkbox'] {
    border: 2px solid $grey-color;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: middle;
    :checked {
      background-color: $primary;
      border-color: $primary;
      color: $white-color;
    }
  }
}

// icons styles
[class^='sis-ico-'] {
  vertical-align: middle !important;
}
.sis-ico-nowifi:before {
  color: #cc0000;
}

.sis-ico-wifi:before {
  color: #339933;
}
.k-button-text {
  [class^='sis-ico-'] {
    font-size: 18px !important;
  }
}
.k-dialog-actions {
  margin: 0;
  border-width: 1px 0 0;
  border-color: inherit;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 8px 8px;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 8px;
  overflow: hidden;
}
.k-window-titlebar {
  padding: 12px 16px;
  border-color: inherit;
  border-width: 0 0 1px;
}
.card-col {
  &.switchable {
    display: flex;
    justify-content: start;
    justify-items: baseline;
    strong.heading {
      margin-left: 12px;
    }
  }
}
@include media('<l-desktop') {
  .k-tooltip {
    visibility: hidden;
  }
}
.hide-network-icon {
  display: none;
}
.hidden {
  display: none;
}

@include media('<=phone') {
  .sis-grid {
    height: 70vh !important;
  }
}
.actionToolbar .k-button-group > .k-button:first-child:not(:only-child) {
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
}
.actionToolbar .k-button-group > .k-button:last-child:not(:only-child) {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}
.k-dialog .k-window-titlebar {
  background-color: $gradient-blue-primary-color !important;
  border-color: $gradient-blue-primary-color !important;
  color: $white-color;
}
.k-button-solid-error {
  color: $white-color !important;
  background-color: $danger-color;
  border-color: $danger-color;
}
.single-field-row {
  .open-editor-icon {
    position: absolute;
    right: 4px;
    top: 40px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;
    z-index: 99;
  }
}
.main-page-section {
  margin: 10px;
  .card-detail-content {
    background-color: #fff;
    border-radius: 4px;
  }
}
.main-page-section {
  .card-title-header {
    border-width: 0 0 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    padding: 0 10px;

    .page-title.page-button-title {
      border: 0;
      padding: 15px 0;
    }
  }
}
.action-icon-wrapper {
  display: flex;
  align-items: center;
  color: $topbar-title-color;
  cursor: pointer;
}

.k-i-close::before {
  content: '✕' !important;
  color: black;
  font-weight: 900;
  font-size: larger;
}
.k-icon {
  width: 1em;
  height: 1em;
  outline: 0;
  font-size: 16px;
  font-family: 'WebComponentsIcons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  text-decoration: none;
  flex-shrink: 0;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.k-switch-lg {
  margin-left: 12px;
  margin-top: 3px;
  .k-switch-track {
    margin-left: 5px;
    padding: 10px;
  }
  .k-switch-thumb {
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
}
.k-grid-norecords-template {
  border: 0;
  text-align: center;
}
.trash-icon::before {
  font-size: larger;
  color: $danger-color;
  cursor: pointer;
}
.detail-grid-section {
  .k-tabstrip-content {
    padding-block: 0px;
    padding-inline: 0px;
  }
  .k-tabstrip-items-wrapper .k-item.k-active::after {
    margin-inline: 10px;
  }
}
.add-page-section {
  .action-buttons-container {
    display: flex;
    width: 100%;
    .action-icon-wrapper {
      display: flex;
      align-items: center;
      color: $topbar-title-color;
      width: 20px;
    }
  }
  .detail-card {
    .detail-card-body {
      width: 60%;
    }
    .k-card-header {
      padding: 12px 12px;
    }
    .k-card-body {
      padding-inline: 6px;
    }
    .card-title-secondary {
      margin: 0;
      font-size: 20px;
    }
    .single-field-row .field-wrapper {
      padding: 10px;
    }
    .single-row-60 {
      width: 60%;
    }
    .single-row-80 {
      width: 80%;
    }
    .single-row-100 {
      width: 100%;
    }
  }
  .tophead {
    .display-text {
      font-size: 24px;
      font-family: Roboto, 'Helvetica Neue', sans-serif;
      font-weight: 500;
      letter-spacing: 0;
      word-break: break-word;
      overflow-wrap: break-word;
    }
    .desciption-label {
      margin-left: 21px;
    }
  }
  .help-button {
    border: 1px solid $primary !important;
    &:hover {
      background-color: $primary !important;
      color: $white-color;
    }
  }
  .alert {
    margin: 0 10px !important;
  }
}
@include media('<=tablet') {
  .add-page-section {
    .detail-card {
      .detail-card-body {
        width: 100%;
      }
      .single-row-60 {
        width: 100%;
      }
      .single-row-80 {
        width: 100%;
      }
      .single-row-100 {
        width: 100%;
      }
    }
  }
}
.k-dialog {
  .dialog-content-msg {
    margin: 25px;
    text-align: center;
  }
}
.alert-warning {
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #fff3cd !important;
  font-size: 15px;
}
