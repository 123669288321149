.custom-card .k-card-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 12px;
  &.pass-fail-count {
    font-size: 28px;
    .success {
      color: #27ab49;
    }
    .failure {
      color: #b93838;
    }
  }
}

.custom-card {
  margin-top: 1rem;
  .sub-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sub-title-text {
      font-size: 0.65rem;
      color: #666666;
      line-height: 2;
      &.success {
        background: #bce3c5;
        padding: 0px 5px;
        border-radius: var(--kendo-border-radius-md, 0.25rem);
      }
    }
    .value {
      font-weight: bold;
      color: #333333;
    }
    .percentage {
      font-size: 0.65rem;
      color: #666666;
    }
  }
  .green-circle {
    display: inline-block;
    width: 10px; /* Size of the circle */
    height: 10px; /* Size of the circle */
    background-color: green; /* Color of the circle */
    border-radius: 50%; /* Makes it a circle */
    margin-right: 8px; /* Space between the circle and the text */
  }
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar .progress {
  height: 100%;
  transition: width 0.3s ease;
}
.donut-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-top: 2%;
}

.donut-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
