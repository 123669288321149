@import '../abstracts/breakpoints';
@import './../abstracts/variables';

// Variables
$blue: #a7c6ed;
$purple: #cbbbf0;
$green: #b2e0b2;
$amber: #ffb3a0;
$yellow: #e9d8c4;
$lavender: #d8b9f3;
$taupe: #d8c9b3;
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-500: #666666;
$gray-800: #1f2937;
$shadow-md:
  0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);

.allow-reposition-div {
  text-align: right;
  padding-right: 20px;
}
.home-page {
  .k-tilelayout {
    & > div {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &.k-grid-flow-col {
      grid-auto-flow: row;
    }
  }
  .k-tilelayout-item-header {
    &.k-card-header {
      .card-header {
        .k-card-title {
          font-size: 22px;
          color: #666666;
        }
        .add-button {
          float: right;
          @include media('<=650px') {
            .k-button-text {
              display: none;
            }
          }
        }
      }
    }
  }
  .k-tilelayout-item-body {
    &.k-card-body {
      position: relative;
      .test-cases-result {
        .k-loading-mask {
          z-index: -1;
        }
      }
    }
  }
}
.tile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  margin-bottom: 0;
  padding: 2px 0;
}

.edit-tiles {
  span {
    font-size: 16px;
  }
}
.k-tilelayout-item.k-card {
  touch-action: auto !important;
  .k-chart-surface {
    touch-action: auto !important;
  }
  &.k-cursor-grab {
    touch-action: none !important;
    .k-chart-surface {
      touch-action: none !important;
    }
  }
}

@include media('>=xl-desktop') {
  .k-tilelayout {
    &.k-grid-flow-col {
      grid-template-columns: repeat(5, minmax(0px, 1fr)) !important;
    }
  }
}
@include media('>phone', '<=tablet') {
  .k-tilelayout {
    &.k-grid-flow-col {
      grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
    }
  }
}
.dashboard-action {
  text-decoration: none;
  height: 100%;
}

// Metric cards
.metric-card {
  box-shadow: $shadow-md;
  position: relative;
  overflow: hidden;
  height: 100%;

  // Left border for each card
  &.blue-card {
    border-left: 4px solid $blue;
  }

  &.purple-card {
    border-left: 4px solid $purple;
  }

  &.green-card {
    border-left: 4px solid $green;
  }

  &.amber-card {
    border-left: 4px solid $amber;
  }
  &.lavender-card {
    border-left: 4px solid $lavender;
  }
  &.yellow-card {
    border-left: 4px solid $yellow;
  }
  .card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .card-info {
    flex: 1;
  }

  .metric-label {
    font-size: 0.875rem;
    color: $gray-500;
    margin-bottom: 0.25rem;
  }

  .metric-value {
    font-size: 2.875rem;
    font-weight: 700;
    color: #666666;
  }

  .metric-change {
    font-size: 0.75rem;
    margin-top: 0.5rem;

    &.positive {
      color: $green;
    }

    &.negative {
      color: #ef4444;
    }
  }
  .dashboard-action {
    text-decoration: none;
  }
  .dashboard-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-radius: 9999px;

    &.blue {
      background-color: rgba($blue, 0.2);
      color: $blue;
    }

    &.purple {
      background-color: rgba($purple, 0.2);
      color: $purple;
    }

    &.green {
      background-color: rgba($green, 0.2);
      color: $green;
    }

    &.amber {
      background-color: rgba($amber, 0.2);
      color: $amber;
    }
    &.yellow {
      background-color: rgba($yellow, 0.2);
      color: $yellow;
    }
    &.lavender {
      background-color: rgba($lavender, 0.2);
      color: $lavender;
    }
  }

  .chart-container {
    margin-top: 1rem;
    height: 4rem;
  }
}

// Activity card
.activity-card {
  box-shadow: $shadow-md;
}

.activity-title {
  padding: 1rem;
  border-bottom: 1px solid $gray-200;
}

.dashboard-font-icons {
  height: 25px;
  width: 25px;
}

// #region Card Transitions

.metric-card {
  transition: all 0.3s ease-in-out;
  .k-card-body {
    padding-block: var(--kendo-spacing-4, 1rem) !important;
    padding-inline: var(--kendo-spacing-4, 1rem) !important;
  }
}

.metric-card .k-card-body:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 100%;
  .dashboard-icon-container {
    transform: rotate(12deg);
  }
}

.card-header {
  transition: all 0.3s ease-in-out;
}

.metric-label {
  transition: color 0.3s ease-in-out;
}

.metric-value {
  transition: all 0.5s ease-in-out;
}

.dashboard-icon-container {
  transition: all 0.3s ease-in-out;
}

/* Entrance animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.metric-card {
  animation: fadeInUp 0.5s ease-out;
}

/* Loading state animation */
@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

// #endregion
