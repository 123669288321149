.tests-run-by-execution-method {
  .k-chart {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .k-chart-title {
    text-align: center;
    color: #666;
  }

  .k-chart-legend {
    text-align: center;
  }
}
.donut-chart-card {
  width: 400px;
  height: 400px;
  margin: auto;
  border: none !important;
}

.donut-chart-title {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: #666666;
  font-size: 2em;
  text-align: center;
}

.donut-chart-header {
  text-align: center;
}

.donut-chart-count {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: #666666;
  font-size: 1.5em;
}

.donut-chart-total {
  font-size: 1em;
  color: rgb(102, 102, 102);
}

.donut-charts-container {
  display: flex; // Enable flexbox
  flex-wrap: wrap; // Allow wrapping
  justify-content: space-around; // Optional: space around items
  gap: 16px; // Space between charts
  margin: 20px; // Margin around the container
}

// Adjust the styles for the DonutChart card
.donut-chart-card {
  max-width: 157px;
  max-height: 268px;
  width: 100%; // Allow the chart to take full width of its container
  height: 100%; // Allow the chart to take full height of its container
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); // Optional: add some shadow for better visibility
}
