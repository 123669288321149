@import '../../../../assets/scss/abstracts/breakpoints';
@import '../../../../assets/scss/abstracts/variables';

.footer,
.k-card-title {
  color: rgb(102, 102, 102);
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.green {
  color: #37b400;
}
.red {
  color: #f31700;
}
.orange {
  color: #ffc000;
}
.test-cases {
  padding: 2%;
}
.date-selector {
  display: flex;
  align-items: end;
  .date-range-selector {
    width: 100%;
    margin-right: 7px;
  }
}
